@import 'styles/variables';
@import 'styles/extensions';

@property --value {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: false;
}

.main-title {
  position: relative;
  background-repeat: no-repeat, no-repeat;
  background-position: 0% 100%, 100%;
  background-size: 10%, 100%;
  
  @media (min-width: 2560px) {
    background-size: calc(8% * #{$zoom}) 100%;
  }

  &__wrapper {
    position: relative;
    @extend %wrapper;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;

    padding-top: 180px;
    padding-bottom: 90px;

    @media (min-width: 2560px) {
      justify-content: center;
      max-width: calc(1280px * #{$zoom});
      padding-top: calc(200px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 80px;
    }

    @media screen and (max-width: 640px) {
      gap: 24px;
      justify-content: flex-start;
      padding-bottom: 80px;
    }
  }

  h1 {
    position: relative;
    z-index: 100;
    margin-bottom: 30px;
    color: $white;
    font-weight: normal;
    font-size: 88px;
    font-family: $secondary-font;
    line-height: 0.9;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    span {
      color: $nav-green;
    }

    @media (min-width: 2560px) {
      font-size: calc(88px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      font-size: 7vw;
      line-height: 7.5vw;
    }

    @media screen and (max-width: 768px) {
      font-size: 9vw;
      line-height: 9.5vw;
    }

    @media screen and (max-width: 450px) {
      font-size: 13vw;
      line-height: 13.5vw;
    }
  }

  strong {
    position: relative;
    z-index: 100;
    display: inline-block;
    max-width: 80%;
    color: $white;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font--extrabold;
    font-style: normal;
    line-height: 36px;

    @media (min-width: 2560px) {
      padding-bottom: calc(40px * #{$zoom});
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      min-width: 100%;
      padding-bottom: 25px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__heading {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  
  &__description {
    flex-basis: 70%;
    max-width: 860px;
    margin-top: 20px;
    color: $white;
    text-align: left;

    @media (min-width: 2560px) {
      max-width: calc(860px * #{$zoom});
    }   

    p {
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 32px;

      @media (min-width: 2560px) {
        font-size: calc(20px * #{$zoom});
        line-height: calc(32px * #{$zoom});
      }
  
      @media screen and (max-width: 450px) {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }    

  &__inview {
    flex-basis: 40%;
  }

  &__image-animation-wrapper {
    position: relative;
    left: 40%;
    width: 100%;
    min-height: 31vh;

    @media screen and (max-width: 1024px) {
      left: 25%;
      width: 160%;
    }

    @media screen and (max-width: 768px) {
      left: 0;
    }
  }

  &__image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200%;

    @media screen and (max-width: 767px) {
      opacity: 0.4;
    }

    &_outter {
      transform: translate(-50%, -50%) scale(0.6);
      animation: rotateImageByClock 10s ease-in-out 3s forwards;
    }

    &_inner {
      transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
      animation: rotateImageOppositeClock 10s ease-in-out 3s forwards;
    }

    &_inner-2 {
      transform: translate(-50%, -50%) scale(0.4) rotate(100deg);
      animation: rotateImageOppositeClock2 10s ease-in-out 3s forwards;
    }

    &_inner-3 {
      transform: translate(-50%, -50%) scale(0.3) rotate(125deg);
      animation: rotateImageOppositeClock3 10s ease-in-out 3s forwards;
    }
  }

  &__image-outter,
  &__image-inner,
  &__image_inner-2,
  &__image_inner-3 {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    transform: translate(-50%, -50%);
  }

  &__hiro-content {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__hero-video {
    position: absolute;
    top: 0;
    width: 100vw;
    height: calc(100vh - 96px);
    margin-top: 96px;
    object-position: top;
    opacity: 0.6;

    @media screen and (min-width: 2560px) {
      opacity: 0.5;
    }

    @media screen and (max-width: 1640px) {
      object-fit: cover;
    }

    &_mobile {
      position: static;
      width: 100%;
      height: 100%;
      margin-top: 0;
      object-fit: contain;
    }
  }

  &__extended-description {
    padding-top: 69px;
    padding-bottom: 78px;
    background-color: $white;

    @media screen and (min-width: 2560px) {
      padding-top: calc(69px * #{$zoom});
      padding-bottom: calc(78px * #{$zoom});
    }

    .wrapper {
      @extend %wrapper;
      max-width: 1140px;

      @media screen and (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
      }
    }

    &-content {
      display: flex;
      gap: 35px;
      justify-content: flex-start;
      align-items: flex-start;

      @media screen and (min-width: 2560px) {
        gap: calc(35px * #{$zoom});
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      > p, div {
        max-width: 50%;
      
        @media screen and (max-width: 768px) {
         max-width: 100%;
        }
      }

      > p {
        font-size: 20px;
        line-height: 1.6;

        @media screen and (min-width: 2560px) {
          font-size: calc(20px * #{$zoom});
        }        
      }
    }

    &_highlighted {
      display: flex;
      flex-direction: column;
      min-width: 510px;
      margin-left: 30px;
      font-size: 40px;
      font-family: $secondary-font;
      line-height: 1;
      text-transform: uppercase;

      @media screen and (min-width: 2560px) {
        min-width: calc(510px * #{$zoom});
        margin-left: calc(30px * #{$zoom});
        font-size: calc(40px * #{$zoom});
      }

      @media screen and (max-width: 1024px) {
        min-width: unset;
      }

      @media screen and (max-width: 768px) {
        margin-left: 0px;
        font-size: 28px;
      }

      span {
        margin-bottom: 6px;
        color: #7401FC;
        font-size: 14px;

        @media screen and (min-width: 2560px) {
          font-size: calc(14px * #{$zoom});
        }
      }
    }
  }
}

@keyframes rotateImageByClock {
  0% {
    transform: translate(-50%, -50%) scale(0.6) ;
  }

  50% {
    transform: translate(-50%, -50%) scale(1) ;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.35) rotate(-15deg);
  }
}

@keyframes rotateImageOppositeClock {
  0% {
    transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
  }

  50% {
    transform: translate(-50%, -50%) scale(0.65) rotate(55deg);
  }

  51% {
    transform: translate(-50%, -50%) scale(0.65) rotate(55deg);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.95) rotate(70deg);
  }
}

@keyframes rotateImageOppositeClock2 {
  0% {
    transform: translate(-50%, -50%) scale(0.4) rotate(100deg);
  }

  50% {
    transform: translate(-50%, -50%) scale(0.5) rotate(105deg);
  }

  51% {
    transform: translate(-50%, -50%) scale(0.5) rotate(105deg);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.85) rotate(135deg);
  }
}

@keyframes rotateImageOppositeClock3 {
  0% {
    transform: translate(-50%, -50%) scale(0.3) rotate(125deg);
  }

  50% {
    transform: translate(-50%, -50%) scale(0.5) rotate(145deg);
  }

  51% {
    transform: translate(-50%, -50%) scale(0.5) rotate(145deg);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.75) rotate(165deg);
  }
}


@keyframes beamLightsWay {
  0%,
  100% {
    left: 40%;
    height: 0;
    transform: rotate(19deg);
    transform-origin: 68% -17%;
  }

  20%,
  80% {
    height: 100%;
  }

  50% {
    left: 40%;
    transform: rotate(11deg);
    transform-origin: 68% -17%;
  }
}