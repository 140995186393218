@import 'styles/variables';
@import 'styles/extensions';

.main {
  width: 100%;
  overflow: hidden;  
  background: $black;

  &__section-background {
    background: $white;
  }

  &__contact-us-form {
    padding-top: 100px;
    background-color: $white;

    @media screen and (min-width: 2560px) {
      padding-top: calc(100px * #{$zoom});
    }

    @media screen and (max-width: 475px) {
      padding-top: 80px;
    }
  }

  &__contact-us-form-wrapper {
    position: relative;
    @extend %wrapper;
    display: flex;
    justify-content: space-between;
    max-width: 1140px;    

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  &__contact-us-form-title-col {
    flex-basis: 30%;
    max-width: 385px;
    color: $main-blue;

    span {
      color: #7401FC;
    }

    p {
      margin-top: 30px;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 50px;
    }

    @media (min-width: 2560px) {
      flex-basis: calc(30% * #{$zoom});
      max-width: calc(345px * #{$zoom});
    }
  }

  &__contact-us-form-title {
    color: $black;
    font-weight: 700;
    font-size: 60px;
    font-family: $secondary-font--extrabold;
    line-height: 70px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(60px * #{$zoom});
      line-height: calc(70px * #{$zoom});
    }

    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 4vw;
      line-height: 4.5vw;
    }

    @media (max-width: 475px) {
      font-size: 40px;
      line-height: 48px;
    }
  }
}

.mobile-hidden {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

@keyframes plantRotate {
  0%{
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
}