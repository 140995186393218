@import 'styles/variables';
@import 'styles/extensions';

.how-it-works {
  background: url('../../../../../asset/resource/hiw-bg.png');
  background-color: $black;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: cover;

  @media (min-width: 2560px) {
    background-position: center;
    background-size: cover;
  }

  &__wrapper {
    @extend %wrapper;
    max-width: 1140px;
    padding-top: 100px;
    padding-bottom: 150px;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-top: calc(100px * #{$zoom});
      padding-bottom: calc(150px * #{$zoom});
    }

    @media screen and (max-width: 475px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  h2 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 70px;
    padding-left: 0;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $white;
    }
  }

  .sub-title {
    position: relative;
    display: inline-block;
    min-width: 290px;
    margin-bottom: 90px;
    color: $white;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font--extrabold;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media (max-width: 475px) {
      margin-bottom: 25px;
      font-size: 18px;
    }

    &::after {
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $white;
      transition: width 0.3s;
      content: '';
    }
  }

  h3 {
    @extend %wrapper;
    position: relative;
    max-width: 1140px;
    padding-right: 25px;
    padding-bottom: 20px;
    padding-left: 0;
    color: $white;
    font-weight: normal;
    font-size: 20px;
    font-family: $secondary-font--extrabold;
    line-height: 125%;
    letter-spacing: 0px;
    text-align: left;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      background-color: #7401FC;
      border-radius: 5px;
      content: '';
    }
    
    @media (min-width: 2560px) {
      font-size: calc(20px * #{$zoom});
    }
    
    @media screen and (max-width: 450px) {
      padding-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__blocks {
    max-width: 1140px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  &__block {
    position: relative;
    display: grid;
    grid-gap: 34px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr 1fr;   
    
   @media screen and (min-width: 2560px) {
    grid-gap: calc(34px * #{$zoom});
   } 

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;   
    }

    article {
      display: flex;
      gap: 32px;
      height: 100%;
      padding: 28px;
      background: linear-gradient(95.79deg, rgba(235, 236, 240, 0.03) 2.74%, rgba(177, 179, 196, 0.024) 100.72%);
      border-radius: 10px;

      @media (min-width: 2560px) {
        font-size: calc(16px * 1.44);
      }

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      @media (max-width: 767px) {        
        max-width: 100%;
        margin-bottom: 40px;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 200px;
    }

    @media (max-width: 475px) {
      flex-direction: column;
      margin-bottom: 0px;
    }

    .how-it-works__image {
      img {
        width: 130px;

        @media screen and (min-width: 2560px) {
          width: calc(130px * #{$zoom});
        }
      }
    }
  }

  &__video {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    padding-top: 150px;

    @media (max-width: 580px) {
      flex-direction: column;
    }

    & > div {
      flex: 1 1 48%;
    }

    figure {
      display: grid;

      img {
        width: 100%;
        max-width: 582px;

        @media (min-width: 2560px) {
          width: calc(582px * #{$zoom});
        }
      }

      figcaption {
        padding-top: 24px;
        color: $white;
        font-size: 24px;
        font-family: $secondary-font;
        font-style: normal;
        line-height: 125%;

        @media (min-width: 2560px) {
          padding-top: calc(24px * #{$zoom});
          font-size: calc(24px * 1.44);
        }
      }
    }
  }

  &__article-content {
    color: $white;
  }
}