$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova-Black', sans-serif;
$secondary-font--bold: 'ProximaNova-Bold', sans-serif;
$secondary-font--extrabold: 'ProximaNova-ExtraBold', sans-serif;

$white:#FFFFFF;
$black: #000000;
$main-gray: #E0E0E0;
$nav-green: #17C583;
$blue: #536DFE;
$slider-gray: #1b1d20;
$main-blue: #7401FC;
$main-yellow: #F1FF17;
$sub-title-green: #17C583;
$card-green: #61B91E;
$background-footer: #646262;
$form-link-blue: #89F6FB;
$footer-gray: #909095;