@import 'styles/variables';

%ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

%wrapper {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0;

  @media only screen and (max-width: 1500px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: 2560px) {
    max-width: calc(1340px * $zoom);
  }
}

%first-level-heading {
  font-size: 58px;
  font-family: $secondary-font;
  line-height: 71px;

  @media (max-width: 767px) {
    font-size: 8.5vw;
    line-height: 9.1vw;
  }
}

%secondary-level-heading {
  font-size: 40px;
  font-family: $secondary-font;
  line-height: 49px;

  @media (max-width: 767px) {
    font-size: 6.5vw;
  }
}

@mixin customLinkAnimation($color, $lineHeight: 1px) {
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: $lineHeight;
    background: $color;
    transition: width 0.3s;
    content: '';
  }

  &:hover {
    &::after {
      left: 0;
      width: 100%;
    }
  }
}

%button-white {
  display: block;
  width: 242px;
  height: 58px;
  margin: 50px auto;
  padding: 0;
  color: $black;
  font-weight: normal;
  font-size: 14px;
  font-family: $secondary-font;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background: $white;
  border: 2px solid $black;
  transition: all 0.3s linear;

  &:hover {
    color: $white;
    text-decoration: none;
    background: $black;
  }

  @media (max-width: 415px) {
    max-width: 100%;
  }
}

%button-colored {
  display: block;
  width: 280px;
  height: 56px;
  margin: 50px auto;
  padding: 0;
  color: $black;
  font-weight: 800;
  font-size: 16px;
  font-family: $secondary-font--extrabold;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(90deg, $blue 0%, $blue 100%);
  border: none;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    color: $blue;
    background: $white;
  }

  &:hover,
  &:focus {
    color: $white;
    box-shadow: inset 0 0 0 2em $blue;
  }

  @media screen and (min-width: 2560px) {
    margin: calc(50px * #{$zoom}) auto;
  }

  @media (max-width: 415px) {
    max-width: 100%;
  }
}