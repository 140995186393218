@import 'styles/variables';
@import 'styles/extensions';

.how-to-use {
  padding-top: 100px;
  background-color: $white;

  @media (min-width: 2560px) {
    padding-top: calc(100px * #{$zoom});
  }

  @media screen and (max-width: 475px) {
    padding-top: 80px;
  }

  &__wrapper {
    @extend %wrapper;
    max-width: 1140px;
    padding-bottom: 0;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(0px * #{$zoom});
    }
  }

  h2 {
    padding-right: 0;
    padding-bottom: 70px;
    padding-left: 0;
    color: $black;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font--extrabold;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $black;
    }
  }

  .sub-title {
    position: relative;
    display: inline-block;
    min-width: 240px;
    margin-bottom: 120px;
    color: #7401FC;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font--extrabold;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      min-width: calc(240px * #{$zoom});
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 820px) {
      margin-bottom: 50px;
    }

    @media (max-width: 475px) {
      margin-bottom: 25px;
      font-size: 18px;
    }

    &::after {
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #7401FC;
      transition: width 0.3s;
      content: '';

      @media screen and (min-width: 2560px) {
        top: calc(-10px * #{$zoom});
      }
    }
  }

  &__items-block {
    padding-bottom: 150px;

    &.last {
      padding-bottom:100px;

      @media (max-width: 475px) {
        padding-bottom: 50px;
      }

      h3 {
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 2px solid $main-blue;
      }
    }

    @media (min-width: 2560px) {
      padding-bottom: calc(140px * #{$zoom});
    }

    @media (max-width: 640px) {
      padding-bottom: 30px;
    }

    h3 {
      color: $black;
      font-weight: normal;
      font-size: 24px;
      font-family: $secondary-font;
      line-height: 125%;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;

      &.small {
        padding-bottom: 10px;
        border-bottom: none;
      }
  
      @media (min-width: 2560px) {
        font-size: calc(24px * #{$zoom});
      }
    }

    span {
      color: $main-blue;
      font-weight: normal;
      font-size: 14px;
      line-height: 116%;
      text-transform: uppercase;

      @media (min-width: 2560px) {
        font-size: calc(14px * #{$zoom});
      }
    }
  }

  &__item-image {
    @media (max-width: 680px) {
      display: flex;
      justify-content: center;
    }

    img {
      // animation: plantChange 8s ease infinite;
    }
  }

  &__item-text {
    display: flex;
    justify-content: flex-start;
    color: $black;

    @media (max-width: 680px) {
      flex-direction: column;
      row-gap: 30px;
      margin-top: 0;
    }

    & > div {
      flex: 0 0 1;

      @media (max-width: 680px) {
        flex: 1;
      }
    }

    p {
      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
      }
    }

    ul {
      padding-left: 20px;
      color: $black;

      @media (min-width: 680px) {
        margin-right: 40px;
      }

      li {
        color: $black;
      }
    }

    img {
      max-width: 433px;
      margin-top: -110px;
      filter: drop-shadow(2px 2px 2px $black);

      @media (min-width: 2560px) {
        max-width: calc(433px * #{$zoom});
        margin-top: -170px;
        margin-right: -90px;
      }

      @media (max-width: 680px) {
        margin-top: 0;
      }

      @media screen and (max-width: 475px) {
        max-width: 75vw;
      }
    }
  }

  &__item-icon {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #7401FC;

    img {
      display: none;
      width: 100%;
      max-width: 45px;
      margin: 0;
      object-fit: contain;
    }
  }
}

@keyframes plantChange {
  0%,
  100% {
    transform:
      perspective(800px)
      rotateY(25deg) 
      translateY(50px)
      scale(0.65)
      rotateX(10deg);
    opacity: 0.75;
    transition: 8s ease all;
  }

  50% {
    transform:
      perspective(800px)
      rotateY(-15deg)
      translateY(-20px)
      rotateX(10deg)
      scale(0.9);
    opacity: 1;
  }
}