@import 'styles/variables';
@import 'styles/extensions';

.use-cases {
  background-color: $black;

  &__wrapper {
    @extend %wrapper;
    max-width: 1340px;
    padding-top: 100px;
    padding-bottom: 150px;
    overflow: hidden;
    color: $black;

    @media (min-width: 2560px) {
      max-width: calc(1340px * #{$zoom});
      padding-top: calc(100px * #{$zoom});
      padding-bottom: calc(150px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 100px;
    }

    @media screen and (max-width: 475px) {
      padding: 80px 0;
    }

    h2 {
      @extend %wrapper;
      max-width: 1140px;
      padding-right: 0;
      padding-bottom: 90px;
      padding-left: 0;
      color: $main-gray;
      font-weight: 800;
      font-size: 60px;
      font-family: $secondary-font;
      line-height: 64px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;

      span {
        color: $blue;
        font-weight: normal;
      }
  
      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
        padding-bottom: calc(90px * #{$zoom});
        font-size: calc(60px * #{$zoom});
        line-height: calc(64px * #{$zoom});
      }
  
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        gap: 40px;
        font-size: 48px;
        line-height: 56px;
      }

      @media screen and (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
  
      @media screen and (max-width: 450px) {
        padding-bottom: 70px;
        font-size: 32px;
        line-height: 36px;
      }
    }

    .sub-title-wrapper {
      @extend %wrapper;
      max-width: 1140px;
      padding-right: 0;
      padding-left: 0;

      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
      }

      @media screen and (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .sub-title {
      position: relative;
      display: inline-block;
      min-width: 240px;
      margin-bottom: 35px;
      color: $white;
      font-weight: 800;
      font-size: 32px;
      font-family: $secondary-font--extrabold;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
  
      @media (min-width: 2560px) {
        min-width: calc(240px * #{$zoom});
        margin-bottom: calc(35px * #{$zoom});
        font-size: calc(32px * #{$zoom});
        line-height: calc(36px * #{$zoom});
      }
  
      @media (max-width: 475px) {
        margin-bottom: 25px;
        font-size: 18px;
      }
  
      &::after {
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $white;
        transition: width 0.3s;
        content: '';

        @media screen and (min-width: 2560px) {
          top: calc(-10px * #{$zoom});
        }
      }
    }

    h3 {
      @extend %wrapper;
      max-width: 1140px;
      padding-right: 0;
      padding-bottom: 35px;
      padding-left: 0;
      color: $white;
      font-weight: normal;
      font-size: 40px;
      font-family: $secondary-font--extrabold;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
  
      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(40px * #{$zoom});
        line-height: calc(48px * #{$zoom});
      }
  
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 36px;
      }
  
      @media screen and (max-width: 450px) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    p {
      color: $white;
    }

    .carousel.carousel-slider {
      overflow: visible;

      .control-dots {    
        margin-bottom: -40px;

        .dot {
          width: 13px;
          height: 8px;
          border-radius: 0;
          box-shadow: none;

          @media (min-width: 2560px) {
            width: calc(13px * #{$zoom});
            height: calc(8px * #{$zoom});
          }

          &:hover {
            background: $blue;
          }

          &.selected {
            background: $blue;
          }
        }
      }

      .control-prev.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-next.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;
          transform: rotate(180deg);

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-arrow {
        width: 33px;
        opacity: 1;
        filter: invert(1);

        @media (min-width: 2560px) {
          width: calc(33px * #{$zoom});
        }
        
        @media (max-width: 640px) {
          display: none;
        }
      }
    } 

    .carousel .slide {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__slide-wrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding: 24px;
    background: url('../../../../../asset/resource/slider-bg.png');
    background-position: center;
    background-size: 170% 150%;
    border: 3px solid #FFFFFF0D;
    border-radius: 16px;

    @media screen and  (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding: 0;
    }

    @media screen and (max-width: 1340px) {
      max-width: calc(100% - 120px);
    }

    @media screen and (max-width: 640px) {
      max-width: 100%;
      margin: 0;
    }

    @media screen and (max-width: 475px) {
      margin: 0 20px;
    }
  }

  &__slide {
    position: relative;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    padding: 60px 40px 60px 50px;
    color: $black;
    background-color: transparent;
    border-radius: 10px;

    @media (min-width: 2560px) {
      max-width: 100%;
      padding: calc(60px * #{$zoom}) calc(40px * #{$zoom}) calc(60px * #{$zoom}) calc(50px * #{$zoom});
    }

    @media (max-width: 820px) {
      flex-direction: column;
      padding: 50px 0;
    }

    @media screen and (max-width: 475px) {
      padding: 12px 0;
    }
    
    @media (min-width: 640px) and (max-width: 880px) {
      flex-direction: column;
    }

    article,
    figure {
      flex-basis: 50%;
      gap: 50px;
      max-width: 585px;

      @media (min-width: 2560px) {
        max-width: calc(585px * #{$zoom});
      }
    }

    article {
      text-align: left;

      @media (max-width: 880px) {
        flex-basis: 100%;
      }
    }

    figure {
      overflow: hidden;
      border-radius: 0px;

      img {
        @media (min-width: 880px) and (max-width: 1149px) {
          width: 125% !important;
        }
      }
    }

    span {
      padding-bottom: 35px;      
      font-weight: 700;

      @media (min-width: 2560px) {
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }
    }

    p {
      width: 90%;
      line-height: 24px;

      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
        line-height: calc(24px * #{$zoom});
      }
    }

    ul {
      padding-left: 20px;
      list-style: disc;
    }
  }
}